import Vue from 'vue';//引入Vue对象，因为要用它的use
import Router from 'vue-router'//引入vue-router
import ElementUI from 'element-ui';

//使用路由插件
Vue.use(Router);

import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, resolve, reject) {
    if (resolve || reject) return originalPush.call(this, location, resolve, reject)
    return originalPush.call(this, location).catch((e) => { })
}


//配置路由
const router = new Router({
    routes: [
        {
            path: '/',
            redirect: '/Login'
        },
        {
            path: "/Login",
            name: "Login",
            component: () => import("../Home/Login.vue")
        },
        {
            path: "/Login1",
            name: "Login1",
            component: () => import("../Home/Login2.vue")
        },
        {
            path: "/Login2",
            name: "Login2",
            component: () => import("../Home/Login1.vue")
        },
        {
            path: "/Login3",
            name: "Login3",
            component: () => import("../Home/Login3.vue")
        },
        {
            path: "/ksh",
            name: "ksh",
            component: () => import("../ksh/index.vue")
        },
        {
            path: "/ccc",
            name: "ccc",
            component: () => import("../Home/ccc.vue")
        },
        {
            path: "/home",
            name: "home",
            component: () => import('../Home/index.vue')//主页
        },
        {
            path: "/jr",
            name: "jr",
            component: () => import('../Home/JR.vue')//金融
        },
        {
            path: "/cs",
            name: "cs",
            component: () => import('../Home/CS.vue')//城市
        },

        {
            path: "/ny",
            name: "ny",
            component: () => import('../Home/NY.vue')//能源
        },

        {
            path: "/hhfc",
            name: "hhfc",
            component: () => import('../Home/HHFC.vue') //华恒房产
        },

        {
            path: "/gxhb",
            name: "gxhb",
            component: () => import('../Home/GXHB.vue') //高新环保
        },
        {
            path: "/gxyl",
            name: "gxyl",
            component: () => import('../Home/GXYL.vue') //日照新晟供应链有限公司
        },
        {
            path: "/gxyy",
            name: "gxyy",
            component: () => import('../Home/GXYY.vue') //日照高新园区运营管理有限公司 日照新融产业投资发展有限公司
        },
        {
            path: "/gxgq",
            name: "gxgq",
            component: () => import('../Home/GXGQ.vue') //高新股权
        },

        {
            path: "/gxny",
            name: "gxny",
            component: () => import('../Home/GXNY.vue') //高新能源
        },
        {
            path: "/xrzs",
            name: "xrzs",
            component: () => import('../Home/XRZS.vue') //新睿招商
        },

        {
            path: "/ycys",
            name: "ycys",
            component: () => import('../Home/YCYS.vue') //英才饮食 日照新汇产业服务有限公司
        },

        {
            path: "/gxwh",
            name: "gxwh",
            component: () => import('../Home/GXWH.vue') //高新文化
        },
        {
            path: "/gxcl",
            name: "gxcl",
            component: () => import('../Home/GXCL.vue') //高新材料  日照山海路通新材料有限公司
        },
        {
            path: "/gxszyl",
            name: "gxszyl",
            component: () => import('../Home/GXSZYL.vue') //日照高新市政园林建设有限公司3
        },
        {
            path: "/xcjsgc",
            name: "xcjsgc",
            component: () => import('../Home/XCJSGC.vue') //日照新彩建设工程有限公司11
        },
        {
            path: "/zdxm",
            name: "zdxm",
            component: () => import('../Home/ZDXM.vue') //重点项目建设情况
        },
        {
            path: "/dcdb",
            name: "dcdb",
            component: () => import('../Home/DCDB.vue') //督查督办
        },

        {
            path: "/wzxq",
            name: "wzxq",
            component: () => import('../Home/WZXQ.vue') //文章详情
        },
        {
            path: "/dangjian",
            name: "dangjian",
            component: () => import('../Home/DANGJIAN.vue') //党建列表数据页面
        },
        {
            path: "/dangjian1",
            name: "dangjian1",
            component: () => import('../Home/DANGJIAN1.vue') //党建列表数据页面
        },
        {
            path: "/dangjianD",
            name: "dangjianD",
            component: () => import('../Home/DANGJIAN_detail.vue') //党建列表数据页面
        },
        {
            path: "/dangjiancenter",
            name: "dangjiancenter",
            component: () => import('../Home/DANGJIANcenter.vue') //党建列表数据页面
        },
        {
            path: "/Camera",
            name: "Camera",
            component: () => import('../Home/Camera2.vue') //监控
        }
        ,
        {
            path: "/dcdb1",
            name: "dcdb1",
            component: () => import('../Home/dcdb1.vue') //督察督办中    集团会议确定重点事项推进情况
        },
        ,
        {
            path: "/dcdb11",
            name: "dcdb11",
            component: () => import('../Home/dcdb11.vue') //督察督办中    集团会议确定重点事项推进情况
        },
        {
            path: "/xunjianList",
            name: "xunjianList",
            component: () => import('../Home/xunjianList.vue') //督察督办中    集团会议确定重点事项推进情况
        },
        {
            path: "/dcdb2",
            name: "dcdb2",
            component: () => import('../Home/dcdb2.vue') //督察督办中    集团主要领导交办事项
        },
        {
            path: "/dcdb3",
            name: "dcdb3",
            component: () => import('../Home/dcdb3.vue') //督察督办中    集团领导参加上级会议确定情况
        },
        {
            path: "/dcdb4",
            name: "dcdb4",
            component: () => import('../Home/dcdb4.vue') //督察督办中    工委会主要领导近期重要批示件推进情况
        },
        {
            path: "/dcdbEcharts",
            name: "dcdbEcharts",
            component: () => import('../Home/dcdbEcharts.vue') //督察督办   任务状态统计
        },
        {
            path: "/examine",
            name: "examine",
            component: () => import('../Home/examine.vue') //党支部5月份考核情况
        },
        {
            path: "/performance",
            name: "performance",
            component: () => import('../Home/performance.vue') //绩效考核
        },
        {
            path: "/whitelist",
            name: "whitelist",
            component: () => import('../Home/whitelist.vue') //白名单
        },
        {
            path: '/warnscreen',
            name: 'warnscreen',
            component: () => import('../ksh/warnscreen.vue') // 预警大屏
        }
    ]
});


//前置钩子
router.beforeEach((to, from, next) => {
    console.log("123", to, from, next)
    if (to.path == "/" || to.path == "/Login" || to.path == '/Login1' || to.path == '/Login2' || to.path == '/Login3') {
        next()
    } else if (window.sessionStorage.getItem("pageArr")) {
        console.log("pageArr=====" + window.sessionStorage.getItem("pageArr"));
        let pageArr = window.sessionStorage.getItem("pageArr").split(",")
        if (pageArr == []) {
            next()
        }
        let pageArr2 = pageArr.map(item => {
            return "/" + item
        })
        if (pageArr2.includes(to.path)) {
            next()
        } else {
            console.log(from.path);
            ElementUI.Message.error("抱歉,您还没有相关页面权限")
            next("/home")
        }
    }

    let Camera_data = [
        "孟国令",
        "方芳",
        "孔令武",
        "相振良",
    ];
    let name = sessionStorage.getItem("login_name");
    let state = Camera_data.includes(name)
    if (to.path == "/Camera") {
        if (state) {
            next()
        } else {
            ElementUI.Message.error("抱歉,您没有监控中心的权限")
            next(from.path)
        }
    }


    //	to: 目标路由
    //	from: 当前路由

    // next() 跳转  一定要调用

    let token = sessionStorage.getItem("token")
    if (from.path == "/") {  //当前页面时登录页面

    } else {  //当前页面不在登录页面
        if (token || to.path == '/Login1' || to.path == '/Login2' || to.path == '/Login3') {
            next()   //如果登录过放行或者访问Login1放行
        } else {    //没有登录 提示错误  导航至登录页面
            ElementUI.Message.error("抱歉,您还没有登录")
            next("/Login")
        }
    }

    next()
})

//3.6.5
//导出
export default router;